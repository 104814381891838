import {
  mdiAccountCircle,
  mdiDesktopMac,
  mdiGithub,
  mdiLock,
  mdiAlertCircle,
  mdiMonitorShimmer,
  mdiSquareEditOutline,
  mdiTable,
  mdiViewList,
  mdiTelevisionGuide,
  mdiResponsive,
  mdiBorderColor,
  mdiChartTimelineVariantShimmer,
  mdiHelpCircleOutline,
  mdiCart,
  mdiCurrencyUsd,
  mdiFormatListBulleted,
  mdiMessageText,
  mdiDomain,
  mdiAccountMultiplePlusOutline,
  mdiEmailFast,
  mdiFaceManProfile,
  mdiAccountDetails,
  mdiCallSplit,
  mdiBullhornVariantOutline,
  mdiCommentQuote,
  mdiStore24Hour,
  mdiShare,
  mdiViewDashboard,
  mdiCalendarCheck,
  mdiCreditCardCheckOutline,
  mdiThumbUp
} from "@mdi/js";
import { mdiPlusCircleOutline } from '@mdi/js';

const links = [
  "Verify",
  [{
    to: "/verify",
    label: "Verify e-mail",
    icon: mdiEmailFast,
    updateMark: true,
  }],
  "General",
  [
    {
      to: '/dashboard',
      label: 'Dashboard',
      icon: mdiViewDashboard,
      updateMark: true
    },
    {
      to: "/profilev2",
      label: "Profile",
      icon: mdiFaceManProfile,
      updateMark: true,
    },
    {
      to: "/company",
      label: "Business",
      icon: mdiDomain,
      updateMark: true,
    },
  ],
  "Votes",
  [
    {
      to: "/orders",
      label: "Jobs",
      icon: mdiBorderColor,
      updateMark: true,
    },
    // Add under the "Votes" section
    {
      to: "/websites",
      label: "Websites",
      icon: mdiTable,
      updateMark: true,
    },
    {
      to: "/website-subscriptions",
      label: "Plans",
      icon: mdiCalendarCheck, // Import this icon
      updateMark: true,
    },
    {
      to: "/checkout",
      label: "Checkout",
      icon: mdiCart,
      updateMark: true,
    },
  ],
  "Advanced",
  [
    {
      to: '/advanced/profiles',
      label: 'Voting Profiles',
      icon: mdiAccountDetails,
      updateMark: true
    },
    {
      to: '/advanced/profiles/proxy',
      label: 'Proxy Profiles',
      icon: mdiCallSplit,
      updateMark: true
    }
  ],

  "Tokens",
  [
    {
      to: "/products",
      label: "Replenish",
      icon: mdiPlusCircleOutline,
      updateMark: true,
    },
    {
      to: "/subscription-plans", // Add this
      label: "Subscriptions",
      icon: mdiCreditCardCheckOutline, // Import this icon
      updateMark: true,
    },
    {
      to: "/invoices",
      label: "View payments",
      icon: mdiFormatListBulleted,
      updateMark: true,
    },
    {
      href: "https://toplistbot.tolt.io/login",
      label: "Affiliate Center",
      target: "_blank",
      icon: mdiShare,
      updateMark: true,
    },
  ],
  "Help",
  [
    {
      href: "https://discord.gg/fr6BYjJbV6",
      label: "Join Discord",
      target: "_blank",
      icon: mdiBullhornVariantOutline,
      updateMark: true,
    },
    {
      href: "https://vote.toplistbot.com/",
      label: "Suggest Features",
      target: "_blank",
      icon: mdiThumbUp,
      updateMark: true,
    },
    // {
    //   to: "/support",
    //   label: "Support",
    //   icon: mdiMessageText,
    //   updateMark: true,
    // },
    //  {
    //   href: "https://toplistbot.canny.io/public-feedback",
    //   label: "Feature Request",
    //   target: "_blank",
    //   icon: mdiCommentQuote,
    //   updateMark: true,
    // },
  ],
  // "Partners",
  // [
  //   {
  //     href: "https://bumper-oo.com/",
  //     label: "Auto Bumper",
  //     target: "_blank",
  //     icon: mdiStore24Hour,
  //     updateMark: true,
  //   },
  // ]
];

export default links